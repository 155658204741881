import React from 'react';
import PropTypes from 'prop-types';

import { Container } from '../styles/loading';

export default function Loading({ isLoading, cancelar }) {
  const limparLocalStorage = () => {
    localStorage.clear();
    // eslint-disable-next-line no-restricted-globals
    location.reload(true);
  };

  if (!isLoading) return <> </>;
  return (
    <Container>
      <div />
      <span>Carregando...</span>
      {(cancelar) ? (
        <div className="pull-left">
          <br />
          <button
            // style={{ position:relative, top: '15px'}}
            onClick={limparLocalStorage}
            type="button"
            className="btn btn-danger"
          >
            Cancelar
          </button>
        </div>
      ) : ('')}

    </Container>
  );
}

Loading.defaultProps = {
  isLoading: false,
};

Loading.propTypes = {
  isLoading: PropTypes.bool,
};
