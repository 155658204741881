import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isEmail } from 'validator';
import { toast } from 'react-toastify';

import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';

import 'react-toastify/dist/ReactToastify.css';
import * as actions from '../store/modules/auth/actions';

import { Input80px } from '../styles/inputGroup';

import Card from '../components/card';
import Loading from './loading';

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLoading, isLoggedIn } = useSelector((state) => state.auth);
  const cancelar = true;

  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');

  useEffect(() => {
    if (isLoggedIn) { navigate('/home'); }
  }, [isLoggedIn, navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (!isEmail(email)) {
      formErros = true;
      toast.error('E-mail inválido');
    }

    if (senha.length <= 0) {
      formErros = true;
      toast.error('Senha deve ser informada');
    }

    if (formErros) return;

    dispatch(actions.loginRequest({ email, senha }));
  };

  return (
    <div className="row">
      <Loading isLoading={isLoading} cancelar={cancelar} />

      <div className="col-md-9 offset-md-2">
        <div className="bs-docs-section">
          <Card title="Login">
            <div className="row">
              <div className="col-lg-12">
                <div className="bs-component">
                  <div className="row form-elo">
                    <div className="col-12">
                      <div className="p-inputgroup">
                        <Input80px className="p-inputgroup-addon">
                          E-mail
                        </Input80px>
                        <InputText
                          id="inputEmail"
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row form-elo">
                    <div className="col-12">
                      <div className="p-inputgroup">
                        <Input80px className="p-inputgroup-addon">
                          Senha
                        </Input80px>
                        <Password
                          toggleMask
                          feedback={false}
                          id="inputSenha"
                          value={senha}
                          onChange={e => setSenha(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={handleSubmit}
                    type="button"
                    className="btn btn-success"
                  >
                    <i className="pi pi-sign-in"></i>
                    Entrar
                  </button>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}
