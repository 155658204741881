import React from 'react';

import { Route, Routes } from 'react-router-dom';

import Login from '../pages/login';
import Home from '../pages/home';
import PrivateRoute from './privateRoute';
import ConsultaUsuario from '../pages/administrador/usuario/consultaUsuario';
import CadastroUsuario from '../pages/administrador/usuario/cadastroUsuario';
import ConsultaRamoNegocio from '../pages/administrador/ramoNegocio/consultaRamoNegocio';
import CadastroRamoNegocio from '../pages/administrador/ramoNegocio/cadastroRamoNegocio';
import ConsultaUnidadeFederacao from '../pages/administrador/unidadeFederacao/consultaUnidadeFederacao';
import CadastroUnidadeFederacao from '../pages/administrador/unidadeFederacao/cadastroUnidadeFederacao';
import ConsultaMunicipio from '../pages/administrador/municipio/consultaMunicipio';
import CadastroMunicipio from '../pages/administrador/municipio/cadastroMunicipio';
import ConsultaEmpresa from '../pages/administrador/empresa/consultaEmpresa';
import CadastroEmpresa from '../pages/administrador/empresa/cadastroEmpresa';
import ConsultaUnidadeMedida from '../pages/empresa/unidadeMedida/consultaUnidadeMedida';
import CadastroUnidadeMedida from '../pages/empresa/unidadeMedida/cadastroUnidadeMedida';
import ConsultaGrupo from '../pages/empresa/grupo/consultaGrupo';
import CadastroGrupo from '../pages/empresa/grupo/cadastroGrupo';
import ConsultaGrupoAcesso from '../pages/empresa/grupoAcesso/consultaGrupoAcesso';
import CadastroGrupoAcesso from '../pages/empresa/grupoAcesso/cadastroGrupoAcesso';
import ConsultaSubGrupo from '../pages/empresa/subGrupo/consultaSubGrupo';
import CadastroSubGrupo from '../pages/empresa/subGrupo/cadastroSubGrupo';
import ConsultaContaAnalitica from '../pages/empresa/contaAnalitica/consultaContaAnalitica';
import CadastroContaAnalitica from '../pages/empresa/contaAnalitica/cadastroContaAnalitica';
import ConsultaCest from '../pages/administrador/cest/consultaCest';
import CadastroCest from '../pages/administrador/cest/cadastroCest';
import ConsultaCfop from '../pages/administrador/cfop/consultaCfop';
import CadastroCfop from '../pages/administrador/cfop/cadastroCfop';
import ConsultaCsosn from '../pages/administrador/csosn/consultaCsosn';
import CadastroCsosn from '../pages/administrador/csosn/cadastroCsosn';
import ConsultaCst from '../pages/administrador/cst/consultaCst';
import CadastroCst from '../pages/administrador/cst/cadastroCst';
import CadastroGeneroProduto from '../pages/administrador/generoProduto/cadastroGeneroProduto';
import ConsultaGeneroProduto from '../pages/administrador/generoProduto/consultaGeneroProduto';
import CadastroIcmsModalidadeBc from '../pages/administrador/icmsModalidadeBc/cadastroIcmsModalidadeBc';
import ConsultaIcmsModalidadeBc from '../pages/administrador/icmsModalidadeBc/consultaIcmsModalidadeBc';
import CadastroIcmsModalidadeBcSt from '../pages/administrador/icmsModalidadeBcSt/cadastroIcmsModalidadeBcSt';
import ConsultaIcmsModalidadeBcSt from '../pages/administrador/icmsModalidadeBcSt/consultaIcmsModalidadeBcSt';
import CadastroIpi from '../pages/administrador/ipi/cadastroIpi';
import ConsultaIpi from '../pages/administrador/ipi/consultaIpi';
import CadastroNcm from '../pages/administrador/ncm/cadastroNcm';
import ConsultaNcm from '../pages/administrador/ncm/consultaNcm';
import CadastroOrigemMercadoria from '../pages/administrador/origemMercadoria/cadastroOrigemMercadoria';
import ConsultaOrigemMercadoria from '../pages/administrador/origemMercadoria/consultaOrigemMercadoria';
import CadastroPisCofins from '../pages/administrador/pisCofins/cadastroPisCofins';
import ConsultaPisCofins from '../pages/administrador/pisCofins/consultaPisCofins';
import CadastroServico from '../pages/administrador/servico/cadastroServico';
import ConsultaServico from '../pages/administrador/servico/consultaServico';
import CadastroTipoProduto from '../pages/administrador/tipoProduto/cadastroTipoProduto';
import ConsultaTipoProduto from '../pages/administrador/tipoProduto/consultaTipoProduto';
import ConsultaProduto from '../pages/empresa/produto/consultaProduto';
import AlterarSenha from '../pages/administrador/usuario/alterarSenha';
import ConsultaFechamentoCaixa from '../pages/empresa/fechamentoCaixa/consultaFechamentoCaixa';
import ConsultaParticipante from '../pages/empresa/participante/consultaParticipante';
import CadastroParticipante from '../pages/empresa/participante/cadastroParticipante';
import ConsultaResumoDav from '../pages/empresa/resumoDav/consultaResumoDav';
import ConsultaResumoNfce from '../pages/empresa/resumoNfce/consultaResumoNfce';
import ConsultaCentroCusto from '../pages/empresa/centroCusto/consultaCentroCusto';
import ConsultaBandeiraCartao from '../pages/empresa/bandeiraCartao/consultaBandeiraCartao';
import ConsultaCarteiraDigital from '../pages/empresa/carteiraDigital/consultaCarteiraDigital';
import ConsultaAdministradoraCartao from '../pages/empresa/administradoraCartao/consultaAdministradoraCartao';
import ConsultaOperador from '../pages/empresa/operador/consultaOperador';
import CadastroOperador from '../pages/empresa/operador/cadastroOperador';
import ConsultaMeioPagamento from '../pages/empresa/meioPagamento/consultaMeioPagamento';
import ConsultaBanco from '../pages/administrador/banco/consultaBanco';
import CadastroBanco from '../pages/administrador/banco/cadastroBanco';
import ConsultaContaBancaria from '../pages/empresa/contaBancaria/consultaContaBancaria';
import ConsultaMovimentacaoFinanceira from '../pages/empresa/movimentacaoFinanceira/consultaMovimentacaoFinanceira';
import ConsultaPrevisaoFinanceira from '../pages/empresa/previsaoFinanceira/consultaPrevisaoFinanceira';
import ConsultaMovimentacaoBancaria from '../pages/empresa/movimentacaoBancaria/consultaMovimentacaoBancaria';
import ConsultaLivroCaixa from '../pages/empresa/livroCaixa/consultaLivroCaixa';
import ConsultaFluxoCaixa from '../pages/empresa/fluxoCaixa/consultaFluxoCaixa';
import PainelFinanceiro from '../pages/empresa/painelFinanceiro/painelFinanceiro';
import ConsultaOfxLayoutNumero from '../pages/empresa/ofxLayoutNumero/consultaOfxLayoutNumero';
import ConsultaFinanceiroDre from '../pages/empresa/financeiroDre/consultaFinanceiroDre';
import ConsultaFilial from '../pages/empresa/filial/consultaFilial';
import ConfiguracaoSistema from '../pages/empresa/configuracaoSistema/configuracaoSistema';
import ConsultaEstampariaBordado from '../pages/empresa/estamparia/estampariaBordado/consultaEstampariaBordado';
import ConsultaEstampariaSilk from '../pages/empresa/estamparia/estampariaSilk/consultaEstampariaSilk';
import ConsultaEstampariaModelo from '../pages/empresa/estamparia/estampariaModelo/consultaEstampariaModelo';
import ConsultaEstampariaTamanho from '../pages/empresa/estamparia/estampariaTamanho/consultaEstampariaTamanho';
import ConsultaEstampariaMalha from '../pages/empresa/estamparia/estampariaMalha/consultaEstampariaMalha';
import ConsultaEstampariaCor from '../pages/empresa/estamparia/estampariaCor/consultaEstampariaCor';
import ConsultaEstampariaGola from '../pages/empresa/estamparia/estampariaGola/consultaEstampariaGola';
import ConsultaEstampariaGolaAcabamento from '../pages/empresa/estamparia/estampariaGolaAcabamento/consultaEstampariaGolaAcabamento';
import ConsultaEstampariaPedido from '../pages/empresa/estamparia/estampariaPedido/consultaEstampariaPedido';
import ConsultaEstampariaMangaAcabamento from '../pages/empresa/estamparia/estampariaMangaAcabamento/consultaEstampariaMangaAcabamento';
import EstampariaPrecificacao from '../pages/empresa/estamparia/estampariaPrecificacao/estampariaPrecificacao';
import GerarEstampariaOrdemProducao from '../pages/empresa/estamparia/estampariaOrdemProducao/gerarEstampariaOrdemProducao';
import ConsultaEstampariaBolso from '../pages/empresa/estamparia/estampariaBolso/consultaEstampariaBolso';
import ConsultaEstampariaOrdemProducao from '../pages/empresa/estamparia/estampariaOrdemProducao/consultaEstampariaOrdemProducao';
import ConsultaEstampariaOrcamento from './../pages/empresa/estamparia/estampariaOrcamento/consultaEstampariaOrcamento';
import ConsultaRecebimentoEmAberto from './../pages/administrador/eloRecebimento/consultaRecebimentoEmAberto';
import GerarRecebimento from './../pages/administrador/eloRecebimento/gerarRecebimento';
import ConsultaEstampariaManga from './../pages/empresa/estamparia/estampariaManga/consultaEstampariaManga';
import ConsultaEstampariaSublimacao from './../pages/empresa/estamparia/estampariaSublimacao/consultaEstampariaSublimacao';
import ConsultaEstampariaAcessorio from './../pages/empresa/estamparia/estampariaAcessorio/consultaEstampariaAcessorio';
import ConsultaEstampariaItemPadrao from './../pages/empresa/estamparia/estampariaItemPadrao/consultaEstampariaItemPadrao';
import RemessaBoleto from './../pages/administrador/eloRecebimento/remessaBoleto';
import ConsultaEstampariaCaixaReforco from './../pages/empresa/estamparia/estampariaCaixa/consultaEstampariaCaixaReforco';
import ConsultaEstampariaCaixaSangria from './../pages/empresa/estamparia/estampariaCaixa/consultaEstampariaCaixaSangria';
import ConsultaEstampariaRecebimentoPedido from './../pages/empresa/estamparia/estampariaRecebimentoPedido/consultaEstampariaRecebimentoPedido';
import RelatorioEstampariaOrcamento from './../pages/empresa/relatorio/relatorioEstampariaOrcamento';
import RelatorioEstampariaPedido from './../pages/empresa/relatorio/relatorioEstampariaPedido';
import RelatorioEstampariaOrdemProducao from './../pages/empresa/relatorio/relatorioEstampariaOrdemProducao';
import RelatorioEstampariaCaixa from './../pages/empresa/relatorio/relatorioEstampariaCaixa';
import RelatorioEstampariaRecebimentoPedidoAberto from './../pages/empresa/relatorio/relatorioEstampariaRecebimentoPedidoAberto';
import RelatorioEstampariaServicoPedidoEmAberto from './../pages/empresa/relatorio/relatorioEstampariaServicoEmAberto';
import RelatorioEstampariaServico from './../pages/empresa/relatorio/relatorioEstampariaServico';
import StatusServico from './../pages/empresa/nfe/statusServico/statusServico';
import ConsultaNFePropria from './../pages/empresa/nfe/consultaNFe';
import EnvioNFePropria from './../pages/empresa/nfe/envio/envioNFePropria';
import RelatorioNFe from './../pages/empresa/relatorio/relatorioNFe';
import ConsultaNaturezaOperacao from './../pages/empresa/naturezaOperacao/consultaNaturezaOperacao';
import RelatorioEstampariaPedidoFinanceiro from './../pages/empresa/relatorio/relatorioEstampariaPedidoFinanceiro';
import AtualizaTabelaFiscal from './../pages/administrador/atualizaTabelaFiscal/atualizaTabelaFiscal';
import ConsultaConversaoCfop from '../pages/empresa/conversaoCfop/consultaConversaoCfop';
import ConsultaConversaoCsosnCst from '../pages/empresa/conversaoCsosnCst/consultaConversaoCsosnCst';
import GerarSintegra from '../pages/empresa/arquivoFiscal/sintegra/gerarSintegra';
import ExportarXML from '../pages/empresa/nfe/exportarXML/exportarXML';
import RelatorioEstampariaPedidoVendedores from './../pages/empresa/relatorio/relatorioEstampariaPedidoVendedores';
import RelatorioEstampariaOrcamentoVendedores from './../pages/empresa/relatorio/relatorioEstampariaOrcamentoVendedores';
import RelatorioEstampariaOrdemProducaoVendedores from './../pages/empresa/relatorio/relatorioEstampariaOrdemProducaoVendedores';
import ConsultaVendedor from '../pages/empresa/vendedor/consultaVendedor';
import CadastroVendedor from '../pages/empresa/vendedor/cadastroVendedor';
import ConsultaTecnico from '../pages/empresa/tecnico/consultaTecnico';
import ConsultaOficina from '../pages/empresa/oficina/consultaOficina';
import ConsultaNFCe from './../pages/empresa/nfce/consultaNFCe';
import EmissaoNFCe from './../pages/empresa/nfce/emissaoNFCe';
import MonitorNFCe from './../pages/empresa/nfce/monitorNFCe';
import Pagamento from './../pages/empresa/pagamento/pagamento';
import ConfiguracaoShipay from '../pages/administrador/configuracaoShipay/configuracaoShipay';
import ConsultaModulo from '../pages/suporte/modulo/consultaModulo';
import CadastroModulo from '../pages/suporte/modulo/cadastroModulo';
import ConsultaTicket from '../pages/suporte/ticket/consultaTicket';
import CadastroTicket from '../pages/suporte/ticket/cadastroTicket';
import AtualizaEstoque from '../pages/empresa/atualizaEstoque/atualizaEstoque';
import ConsultaMovimentacaoProduto from '../pages/empresa/movimentacaoProduto/consultaMovimentacaoProduto';
import CadastroMovimentacaoProduto from '../pages/empresa/movimentacaoProduto/cadastroMovimentacaoProduto';
import RelatorioMovimentacaoProduto from '../pages/empresa/relatorio/relatorioMovimentacaoProduto';
import ConsultaCTe from './../pages/empresa/cte/consultaCTe';
import ConsultaPontoVenda from './../pages/empresa/pontoVenda/consultaPontoVenda';
import CadastroNovaVenda from './../pages/empresa/pontoVenda/cadastrarNovaVenda';
import ConsultaOrdemServicoAparelho from './../pages/empresa/ordemServico/aparelho/consultaOrdemServicoAparelho';
import RelatorioOsAparelho from './../pages/empresa/relatorio/relatorioOsAparelho';
import ConsultaMotorista from '../pages/empresa/motorista/consultaMotorista';
import CadastroMotorista from '../pages/empresa/motorista/cadastroMotorista';
import ConsultaVeiculo from '../pages/empresa/veiculo/consultaVeiculo';
import CadastroVeiculo from '../pages/empresa/veiculo/cadastroVeiculo';
import ConsultaMDFe from './../pages/empresa/mdfe/consultaMDFe';

export default function Rotas() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/home" element={<PrivateRoute linkName="Home"><Home /></PrivateRoute>} />
      <Route path="/consultausuario" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><ConsultaUsuario /></PrivateRoute>} />
      <Route path="/cadastrousuario" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroUsuario /></PrivateRoute>} />
      <Route path="/cadastrousuario/:id" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroUsuario /></PrivateRoute>} />
      <Route path="/consultaramonegocio" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><ConsultaRamoNegocio /></PrivateRoute>} />
      <Route path="/cadastroramonegocio" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroRamoNegocio /></PrivateRoute>} />
      <Route path="/cadastroramonegocio/:id" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroRamoNegocio /></PrivateRoute>} />
      <Route path="/consultaunidadefederacao" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><ConsultaUnidadeFederacao /></PrivateRoute>} />
      <Route path="/cadastrounidadefederacao" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroUnidadeFederacao /></PrivateRoute>} />
      <Route path="/cadastrounidadefederacao/:id" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroUnidadeFederacao /></PrivateRoute>} />
      <Route path="/consultamunicipio" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><ConsultaMunicipio /></PrivateRoute>} />
      <Route path="/cadastromunicipio" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroMunicipio /></PrivateRoute>} />
      <Route path="/cadastromunicipio/:id" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroMunicipio /></PrivateRoute>} />
      <Route path="/consultaempresa" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><ConsultaEmpresa /></PrivateRoute>} />
      <Route path="/cadastroempresa" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroEmpresa /></PrivateRoute>} />
      <Route path="/consultaoperador" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><ConsultaOperador /></PrivateRoute>} />
      <Route path="/cadastrooperador" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroOperador /></PrivateRoute>} />
      <Route path="/cadastroempresa/:id" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroEmpresa /></PrivateRoute>} />
      <Route path="/consultacest" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><ConsultaCest /></PrivateRoute>} />
      <Route path="/cadastrocest" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroCest /></PrivateRoute>} />
      <Route path="/cadastrocest/:id" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroCest /></PrivateRoute>} />
      <Route path="/consultacfop" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><ConsultaCfop /></PrivateRoute>} />
      <Route path="/cadastrocfop" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroCfop /></PrivateRoute>} />
      <Route path="/cadastrocfop/:id" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroCfop /></PrivateRoute>} />
      <Route path="/consultacsosn" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><ConsultaCsosn /></PrivateRoute>} />
      <Route path="/cadastrocsosn" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroCsosn /></PrivateRoute>} />
      <Route path="/cadastrocsosn/:id" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroCsosn /></PrivateRoute>} />
      <Route path="/consultacst" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><ConsultaCst /></PrivateRoute>} />
      <Route path="/cadastrocst" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroCst /></PrivateRoute>} />
      <Route path="/cadastrocst/:id" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroCst /></PrivateRoute>} />
      <Route path="/consultageneroproduto" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><ConsultaGeneroProduto /></PrivateRoute>} />
      <Route path="/cadastrogeneroproduto" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroGeneroProduto /></PrivateRoute>} />
      <Route path="/cadastrogeneroproduto/:id" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroGeneroProduto /></PrivateRoute>} />
      <Route path="/consultaicmsmodalidadebc" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><ConsultaIcmsModalidadeBc /></PrivateRoute>} />
      <Route path="/cadastroicmsmodalidadebc" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroIcmsModalidadeBc /></PrivateRoute>} />
      <Route path="/cadastroicmsmodalidadebc/:id" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroIcmsModalidadeBc /></PrivateRoute>} />
      <Route path="/consultaicmsmodalidadebcst" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><ConsultaIcmsModalidadeBcSt /></PrivateRoute>} />
      <Route path="/cadastroicmsmodalidadebcst" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroIcmsModalidadeBcSt /></PrivateRoute>} />
      <Route path="/cadastroicmsmodalidadebcst/:id" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroIcmsModalidadeBcSt /></PrivateRoute>} />
      <Route path="/consultaipi" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><ConsultaIpi /></PrivateRoute>} />
      <Route path="/cadastroipi" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroIpi /></PrivateRoute>} />
      <Route path="/cadastroipi/:id" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroIpi /></PrivateRoute>} />
      <Route path="/consultancm" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><ConsultaNcm /></PrivateRoute>} />
      <Route path="/cadastroncm" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroNcm /></PrivateRoute>} />
      <Route path="/cadastroncm/:id" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroNcm /></PrivateRoute>} />
      <Route path="/consultaorigemmercadoria" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><ConsultaOrigemMercadoria /></PrivateRoute>} />
      <Route path="/cadastroorigemmercadoria" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroOrigemMercadoria /></PrivateRoute>} />
      <Route path="/cadastroorigemmercadoria/:id" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroOrigemMercadoria /></PrivateRoute>} />
      <Route path="/consultapiscofins" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><ConsultaPisCofins /></PrivateRoute>} />
      <Route path="/cadastropiscofins" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroPisCofins /></PrivateRoute>} />
      <Route path="/cadastropiscofins/:id" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroPisCofins /></PrivateRoute>} />
      <Route path="/consultaservico" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><ConsultaServico /></PrivateRoute>} />
      <Route path="/cadastroservico" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroServico /></PrivateRoute>} />
      <Route path="/cadastroservico/:id" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroServico /></PrivateRoute>} />
      <Route path="/consultatipoproduto" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><ConsultaTipoProduto /></PrivateRoute>} />
      <Route path="/cadastrotipoproduto" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroTipoProduto /></PrivateRoute>} />
      <Route path="/cadastrotipoproduto/:id" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroTipoProduto /></PrivateRoute>} />
      <Route path="/consultabanco" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><ConsultaBanco /></PrivateRoute>} />
      <Route path="/cadastrobanco" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroBanco /></PrivateRoute>} />
      <Route path="/cadastrobanco/:id" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroBanco /></PrivateRoute>} />
      <Route path="/consultarecebimentoemaberto" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><ConsultaRecebimentoEmAberto /></PrivateRoute>} />
      <Route path="/gerarrecebimento" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><GerarRecebimento /></PrivateRoute>} />
      <Route path="/remessaboleto" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><RemessaBoleto /></PrivateRoute>} />
      <Route path="/atualizatabelafiscal" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><AtualizaTabelaFiscal /></PrivateRoute>} />
      <Route path="/admconsultamodulo" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><ConsultaModulo /></PrivateRoute>} />
      <Route path="/admcadastromodulo" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroModulo /></PrivateRoute>} />
      <Route path="/admconsultaticket" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><ConsultaTicket /></PrivateRoute>} />
      <Route path="/admcadastroticket" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><CadastroTicket /></PrivateRoute>} />
      <Route path="/configuracaoshipay" element={<PrivateRoute tipoUsuarioRota="ADMINISTRADOR"><ConfiguracaoShipay /></PrivateRoute>} />

      <Route path="/consultaunidademedida" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="ConsultaUnidadeMedida"><ConsultaUnidadeMedida /></PrivateRoute>} />
      <Route path="/cadastrounidademedida" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><CadastroUnidadeMedida /></PrivateRoute>} />
      <Route path="/cadastrounidademedida/:id" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><CadastroUnidadeMedida /></PrivateRoute>} />
      <Route path="/consultagrupo" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="ConsultaGrupo"><ConsultaGrupo /></PrivateRoute>} />
      <Route path="/cadastrogrupo" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><CadastroGrupo /></PrivateRoute>} />
      <Route path="/consultagrupoacesso" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="ConsultaGrupoAcesso"><ConsultaGrupoAcesso /></PrivateRoute>} />
      <Route path="/cadastrogrupoacesso" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><CadastroGrupoAcesso /></PrivateRoute>} />
      <Route path="/cadastrogrupo/:id" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><CadastroGrupo /></PrivateRoute>} />
      <Route path="/consultasubgrupo" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="ConsultaSubGrupo"><ConsultaSubGrupo /></PrivateRoute>} />
      <Route path="/cadastrosubgrupo" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><CadastroSubGrupo /></PrivateRoute>} />
      <Route path="/cadastrosubgrupo/:id" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><CadastroSubGrupo /></PrivateRoute>} />
      <Route path="/consultacontaanalitica" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="ConsultaContaAnalitica"><ConsultaContaAnalitica /></PrivateRoute>} />
      <Route path="/cadastrocontaanalitica" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><CadastroContaAnalitica /></PrivateRoute>} />
      <Route path="/cadastrocontaanalitica/:id" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><CadastroContaAnalitica /></PrivateRoute>} />
      <Route path="/consultaparticipante" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="ConsultaParticipante"><ConsultaParticipante menuMode /></PrivateRoute>} />
      <Route path="/cadastroparticipante" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><CadastroParticipante /></PrivateRoute>} />
      <Route path="/cadastroparticipante/:id" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><CadastroParticipante /></PrivateRoute>} />
      <Route path="/consultaproduto" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="ConsultaProduto"><ConsultaProduto menuMode /></PrivateRoute>} />
      <Route path="/alterarsenha" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="AlterarSenha"><AlterarSenha /></PrivateRoute>} />
      <Route path="/consultafechamentocaixa" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="ConsultaFechamentoCaixa"><ConsultaFechamentoCaixa /></PrivateRoute>} />
      <Route path="/consultaresumodav" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="ConsultaResumoDav"><ConsultaResumoDav /></PrivateRoute>} />
      <Route path="/consultaresumonfce" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="ConsultaResumoNfce"><ConsultaResumoNfce /></PrivateRoute>} />
      <Route path="/consultacentrocusto" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><ConsultaCentroCusto /></PrivateRoute>} />
      <Route path="/consultaconversaocfop" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="ConsultaConversaoCfop"><ConsultaConversaoCfop /></PrivateRoute>} />
      <Route path="/consultabandeiracartao" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><ConsultaBandeiraCartao /></PrivateRoute>} />
      <Route path="/consultacarteiradigital" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><ConsultaCarteiraDigital /></PrivateRoute>} />
      <Route path="/consultaadministradoracartao" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><ConsultaAdministradoraCartao /></PrivateRoute>} />
      <Route path="/consultaconversaocsosncst" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="ConsultaConversaoCsosnCst"><ConsultaConversaoCsosnCst /></PrivateRoute>} />
      <Route path="/consultanaturezaoperacao" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="ConsultaNaturezaOperacao"><ConsultaNaturezaOperacao /></PrivateRoute>} />
      <Route path="/empconsultaoperador" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="ConsultaOperador"><ConsultaOperador /></PrivateRoute>} />
      <Route path="/empcadastrooperador" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><CadastroOperador /></PrivateRoute>} />
      <Route path="/cadastrooperador/:id" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><CadastroOperador /></PrivateRoute>} />
      <Route path="/consultaofxlayoutnumero" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><ConsultaOfxLayoutNumero /></PrivateRoute>} />
      <Route path="/consultafinanceirodre" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><ConsultaFinanceiroDre /></PrivateRoute>} />
      <Route path="/consultameiopagamento" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><ConsultaMeioPagamento /></PrivateRoute>} />
      <Route path="/consultacontabancaria" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><ConsultaContaBancaria /></PrivateRoute>} />
      <Route path="/consultamovimentacaofinanceira" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="ConsultaMovimentacaoFinanceira"><ConsultaMovimentacaoFinanceira menuMode /></PrivateRoute>} />
      <Route path="/consultaprevisaofinanceira" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="ConsultaPrevisaoFinanceira"><ConsultaPrevisaoFinanceira /></PrivateRoute>} />
      <Route path="/consultamovimentacaobancaria" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="ConsultaMovimentacaoBancaria"><ConsultaMovimentacaoBancaria /></PrivateRoute>} />
      <Route path="/consultalivrocaixa" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="ConsultaLivroCaixa"><ConsultaLivroCaixa /></PrivateRoute>} />
      <Route path="/consultafluxocaixa" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="ConsultaFluxoCaixa"><ConsultaFluxoCaixa /></PrivateRoute>} />
      <Route path="/painelfinanceiro" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="PainelFinanceiro"><PainelFinanceiro /></PrivateRoute>} />
      <Route path="/consultafilial" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><ConsultaFilial /></PrivateRoute>} />
      <Route path="/configuracaosistema" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="ConfiguracaoSistema"><ConfiguracaoSistema /></PrivateRoute>} />
      <Route path="/consultaestampariasilk" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><ConsultaEstampariaSilk /></PrivateRoute>} />
      <Route path="/consultaestampariasublimacao" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><ConsultaEstampariaSublimacao /></PrivateRoute>} />
      <Route path="/consultaestampariabordado" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><ConsultaEstampariaBordado /></PrivateRoute>} />
      <Route path="/consultaestampariamodelo" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><ConsultaEstampariaModelo /></PrivateRoute>} />
      <Route path="/consultaestampariatamanho" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><ConsultaEstampariaTamanho /></PrivateRoute>} />
      <Route path="/consultaestampariamalha" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><ConsultaEstampariaMalha /></PrivateRoute>} />
      <Route path="/consultaestampariacor" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><ConsultaEstampariaCor /></PrivateRoute>} />
      <Route path="/consultaestampariabolso" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><ConsultaEstampariaBolso /></PrivateRoute>} />
      <Route path="/consultaestampariagola" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><ConsultaEstampariaGola /></PrivateRoute>} />
      <Route path="/consultaestampariagolaacabamento" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><ConsultaEstampariaGolaAcabamento /></PrivateRoute>} />
      <Route path="/consultaestampariamanga" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><ConsultaEstampariaManga /></PrivateRoute>} />
      <Route path="/consultaestampariamangaacabamento" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><ConsultaEstampariaMangaAcabamento /></PrivateRoute>} />
      <Route path="/consultaestampariapedido" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><ConsultaEstampariaPedido menuMode /></PrivateRoute>} />
      <Route path="/consultaestampariaorcamento" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><ConsultaEstampariaOrcamento menuMode /></PrivateRoute>} />
      <Route path="/estampariaPrecificacao" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><EstampariaPrecificacao /></PrivateRoute>} />
      <Route path="/gerarestampariaordemproducao" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><GerarEstampariaOrdemProducao /></PrivateRoute>} />
      <Route path="/consultaestampariaordemproducao" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><ConsultaEstampariaOrdemProducao menuMode /></PrivateRoute>} />
      <Route path="/consultaestampariaacessorio" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><ConsultaEstampariaAcessorio menuMode /></PrivateRoute>} />
      <Route path="/consultaestampariaitempadrao" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><ConsultaEstampariaItemPadrao menuMode /></PrivateRoute>} />
      <Route path="/consultaestampariacaixareforco" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><ConsultaEstampariaCaixaReforco /></PrivateRoute>} />
      <Route path="/consultaestampariacaixasangria" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><ConsultaEstampariaCaixaSangria /></PrivateRoute>} />
      <Route path="/consultaestampariarecebimentopedido" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><ConsultaEstampariaRecebimentoPedido /></PrivateRoute>} />
      <Route path="/relatorioestampariaorcamento" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><RelatorioEstampariaOrcamento /></PrivateRoute>} />
      <Route path="/relatorioestampariapedido" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><RelatorioEstampariaPedido /></PrivateRoute>} />
      <Route path="/relatorioestampariapedidofinanceiro" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><RelatorioEstampariaPedidoFinanceiro /></PrivateRoute>} />
      <Route path="/relatorioestampariaordemproducao" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><RelatorioEstampariaOrdemProducao /></PrivateRoute>} />
      <Route path="/relatorioestampariacaixa" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><RelatorioEstampariaCaixa /></PrivateRoute>} />
      <Route path="/relatorioestampariarecebimentopedidoaberto" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><RelatorioEstampariaRecebimentoPedidoAberto /></PrivateRoute>} />
      <Route path="/relatorioestampariaservicopedidoemaberto" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><RelatorioEstampariaServicoPedidoEmAberto /></PrivateRoute>} />
      <Route path="/relatorioestampariaservico" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><RelatorioEstampariaServico /></PrivateRoute>} />
      <Route path="/relatorioestampariapedidovendedores" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><RelatorioEstampariaPedidoVendedores /></PrivateRoute>} />
      <Route path="/relatorioestampariaorcamentovendedores" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><RelatorioEstampariaOrcamentoVendedores /></PrivateRoute>} />
      <Route path="/relatorioestampariaordemproducaovendedores" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><RelatorioEstampariaOrdemProducaoVendedores /></PrivateRoute>} />
      <Route path="/statusservico" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="StatusServico"><StatusServico /></PrivateRoute>} />
      <Route path="/consultanfepropria" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="ConsultaNFePropria"><ConsultaNFePropria tipoMovimentacao="saida" /></PrivateRoute>} />
      <Route path="/envionfepropria" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="EnvioNFePropria"><EnvioNFePropria /></PrivateRoute>} />
      <Route path="/consultanfeterceiro" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="ConsultaNFeTerceiro"><ConsultaNFePropria tipoMovimentacao="entrada" /></PrivateRoute>} />
      <Route path="/relatorionfe" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><RelatorioNFe /></PrivateRoute>} />
      <Route path="/exportarxml" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="ExportarXML"><ExportarXML /></PrivateRoute>} />
      <Route path="/gerarsintegra" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="GerarSintegra"><GerarSintegra /></PrivateRoute>} />
      <Route path="/consultavendedor" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="ConsultaVendedor"><ConsultaVendedor /></PrivateRoute>} />
      <Route path="/cadastrovendedor" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><CadastroVendedor /></PrivateRoute>} />
      <Route path="/cadastrovendedor/:id" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><CadastroVendedor /></PrivateRoute>} />
      <Route path="/consultatecnico" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="ConsultaTecnico"><ConsultaTecnico /></PrivateRoute>} />
      <Route path="/consultaoficina" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="ConsultaOficina"><ConsultaOficina /></PrivateRoute>} />
      <Route path="/consultanfce" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="ConsultaNFCe"><ConsultaNFCe /></PrivateRoute>} />
      <Route path="/emissaonfce" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="EmissaoNFCe"><EmissaoNFCe /></PrivateRoute>} />
      <Route path="/monitornfce" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="MonitorNFCe"><MonitorNFCe /></PrivateRoute>} />
      <Route path="/pagamento" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="Pagamento"><Pagamento /></PrivateRoute>} />
      <Route path="/empresaconsultaticket" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="EmpresaConsultaTicket"><ConsultaTicket /></PrivateRoute>} />
      <Route path="/empresacadastroticket" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><CadastroTicket /></PrivateRoute>} />
      <Route path="/atualizaestoque" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="AtualizaEstoque"><AtualizaEstoque /></PrivateRoute>} />
      <Route path="/consultamovimentacaoproduto" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="ConsultaMovimentacaoProduto"><ConsultaMovimentacaoProduto /></PrivateRoute>} />
      <Route path="/cadastromovimentacaoproduto" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><CadastroMovimentacaoProduto /></PrivateRoute>} />
      <Route path="/relatoriomovimentacaoproduto" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="RelatorioMovimentacaoProduto"><RelatorioMovimentacaoProduto /></PrivateRoute>} />
      <Route path="/consultacte" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><ConsultaCTe /></PrivateRoute>} />
      <Route path="/consultapontovenda" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><ConsultaPontoVenda menuMode /></PrivateRoute>} />
      <Route path="/cadastronovavenda" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><CadastroNovaVenda menuMode /></PrivateRoute>} />
      <Route path="/consultaordemServicoAparelho" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="ConsultaOrdemServicoAparelho"><ConsultaOrdemServicoAparelho menuMode /></PrivateRoute>} />
      <Route path="/relatorioosaparelho" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="RelatorioOrdemServicoAparelho"><RelatorioOsAparelho /></PrivateRoute>} />
      <Route path="/consultamotorista" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="ConsultaMotorista"><ConsultaMotorista /></PrivateRoute>} />
      <Route path="/cadastromotorista" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><CadastroMotorista /></PrivateRoute>} />
      <Route path="/cadastromotorista/:id" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><CadastroMotorista /></PrivateRoute>} />
      <Route path="/consultamdfe" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="ConsultaMDFe"><ConsultaMDFe tipoMovimentacao="saida" /></PrivateRoute>} />
      <Route path="/consultaveiculo" element={<PrivateRoute tipoUsuarioRota="EMPRESA" linkName="ConsultaVeiculo"><ConsultaVeiculo /></PrivateRoute>} />
      <Route path="/cadastroveiculo" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><CadastroVeiculo /></PrivateRoute>} />
      <Route path="/cadastroveiculo/:id" element={<PrivateRoute tipoUsuarioRota="EMPRESA"><CadastroVeiculo /></PrivateRoute>} />

      <Route path="/consultaempresasuporte" element={<PrivateRoute tipoUsuarioRota="SUPORTE"><ConsultaEmpresa /></PrivateRoute>} />
      <Route path="/consultamodulo" element={<PrivateRoute tipoUsuarioRota="SUPORTE"><ConsultaModulo /></PrivateRoute>} />
      <Route path="/cadastromodulo" element={<PrivateRoute tipoUsuarioRota="SUPORTE"><CadastroModulo /></PrivateRoute>} />
      <Route path="/consultaoperdor" element={<PrivateRoute tipoUsuarioRota="SUPORTE" linkName="ConsultaOperador"><ConsultaOperador somenteLeitura="true" /></PrivateRoute>} />
      <Route path="/consultaticket" element={<PrivateRoute tipoUsuarioRota="SUPORTE" linkName="ConsultaTicket"><ConsultaTicket /></PrivateRoute>} />
      <Route path="/cadastroticket" element={<PrivateRoute tipoUsuarioRota="SUPORTE"><CadastroTicket /></PrivateRoute>} />
      <Route path="/suporteconsultarecebimentoemaberto" element={<PrivateRoute tipoUsuarioRota="SUPORTE"><ConsultaRecebimentoEmAberto somenteLeitura="true" /></PrivateRoute>} />
    </Routes>
  );
}
